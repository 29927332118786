import { App, Button, Form, Input, Spin } from "antd";
import _ from "lodash";
import React from "react";
import { useLazyGenerateSurchargeScriptQuery, useSaveSurchargeScriptMutation } from "../../../redux/rtkquery/PricingApi";
import FullScreenModal from "../../common/components/FullScreenModal";
import { PricingSurchargeModel } from "../../models";

type ComponentProps = {
    onModalClose: (pricingSurcharge?: PricingSurchargeModel | null) => void,
    pricingSurcharge: PricingSurchargeModel,
    companyId: number
}

const AddEditSurchargeScript: React.FC<ComponentProps> = (props) => {
    const pricingSurcharge = _.cloneDeep(props.pricingSurcharge);
    const [form] = Form.useForm<PricingSurchargeModel>();
    const [triggerGenerateSurchargeScript, generateSurchargeScriptResult] = useLazyGenerateSurchargeScriptQuery();
    const [triggerSaveSurchargeScript, saveSurchargeScriptResult] = useSaveSurchargeScriptMutation();
    const { notification } = App.useApp();

    const onFinishFailed = () => {
        notification.error({
            message: "Validation Error",
            description: "Please fix highlighted errors",
            placement: "topRight"
        })
    }

    const onFinish = async (values: PricingSurchargeModel) => {

        const _pricingSurcharge = {
            ...pricingSurcharge,
            surchargeScript: _.trim(values.surchargeScript)
        }

        let response = await triggerSaveSurchargeScript({ companyId: props.companyId, id: _pricingSurcharge.id, surchargeScript: _pricingSurcharge.surchargeScript });

        if (response && 'data' in response && response.data.success) {
            props.onModalClose(_pricingSurcharge);
        }
    }

    const generateScript = async () => {
        let response = await triggerGenerateSurchargeScript({ companyId: props.companyId, id: pricingSurcharge.id }, false);

        if (response && 'data' in response) {
            const surchargeScript = response.data?.data;

            if (!surchargeScript) {
                notification.info({
                    message: "Information",
                    description: "No script generated by system",
                    placement: "topRight"
                })
                return;
            }

            form.setFieldValue('surchargeScript', surchargeScript);
        }
    }

    return (
        <FullScreenModal title={
            <h3>{pricingSurcharge.id ? 'Edit' : 'Add'} Script for {pricingSurcharge.surchargeName}</h3>
        }
            centered
            open={true}
            closable={false}
            maskClosable={false}
            keyboard={false}
            width={800}
            destroyOnClose={true}
            footer={
                [
                    <Button key="generate" className="btn-primary-outline"
                        loading={generateSurchargeScriptResult.isFetching}
                        onClick={generateScript}>
                        Generate Script
                    </Button>,
                    <Button key="cancel"
                        onClick={() => props.onModalClose()}
                        disabled={saveSurchargeScriptResult.isLoading}
                    >
                        Cancel
                    </Button>,
                    <Button key="submit" type="primary"
                        onClick={form.submit}
                        loading={saveSurchargeScriptResult.isLoading}>
                        Save
                    </Button>
                ]
            }
        >
            <Spin spinning={saveSurchargeScriptResult.isLoading}>
                <Form
                    layout="vertical"
                    form={form}
                    name="AddEditSurchargeScript"
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    autoComplete="off"
                    initialValues={pricingSurcharge}
                >
                    <div className="text-gray-700 fs-6 fw-normal text-wrap">
                        <p className="mb-1">Provide Price script for the surcharge. Click "Generate Script" to generate a new script.</p>
                    </div>

                    <Form.Item name="surchargeScript" label="Surcharge Script"
                        rules={[{ required: true, message: "'${label}' is required" }]}>
                        <Input.TextArea autoSize rows={7}
                            style={{ minHeight: '200px' }}
                        />
                    </Form.Item>
                </Form>
            </Spin>
        </FullScreenModal>
    )
}

export { AddEditSurchargeScript };

