import { PlusCircleOutlined } from '@ant-design/icons';
import { GridOptions } from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';
import { Button, Card } from 'antd';
import React, { useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useLazyGetPricingRequestListQuery } from '../../../redux/rtkquery/PricingApi';
import { getFormattedDate } from '../../common/CommonFunctions';
import useWindowResize from '../../common/components/useWindowResize';
import { CONST_DATE_FORMAT } from '../../common/Constants';
import { PricingRequestStatusDetails } from '../../common/Enums';
import { PricingRequestModel } from '../../models';

const PricingRequests: React.FC = () => {
    const navigate = useNavigate();
    const [triggerGetPricingRequestList] = useLazyGetPricingRequestListQuery();
    const gridRef = useRef<AgGridReact<PricingRequestModel>>(null);

    const fetchRequestList = async () => {
        const response = await triggerGetPricingRequestList(undefined, false);
        gridRef.current?.api.setRowData(response.data?.data || []);
    }

    // const onDeleteClick = (params: CellClickedEvent<PricingInstructionListItemModel, any>) => {

    //     modal.confirm({
    //         title: "Confirm deletion",
    //         content: <>
    //             <p>Are you sure you would like to delete price book "{params.data?.instructionName}"?</p>
    //             <p className='text-danger fw-bold'>This action cannot be undone.</p>
    //         </>,
    //         okText: "Delete",
    //         okButtonProps: { danger: true },
    //         cancelText: "No",
    //         onOk: () => onDeletePricingInstruction(params)
    //     });
    // }

    // const onDeletePricingInstruction = async (params: any) => {
    //     let response = await triggerDeletePricingInstruction({ id: params.data.id });

    //     if ('data' in response && response.data.success) {
    //         params.api.applyTransaction({ remove: [params.data] });
    //     }
    // }

    const gridOptions: GridOptions<PricingRequestModel> = {
        defaultColDef: {
            sortable: true,
            filter: true,
            resizable: true,
            wrapHeaderText: true,
            autoHeaderHeight: true,
            autoHeight: true,
            suppressMovable: true,
            suppressAutoSize: true,
            onCellClicked: (params) => {
                navigate(`/quotes/${params.data?.pricingRequestId}`)
            }
        },
        columnDefs:
            [
                {
                    field: 'requestName',
                    headerName: 'Name',
                    flex: 1,
                    wrapText: true,
                    cellClass: 'text-primary'
                },
                {
                    field: 'statusId',
                    headerName: 'Status',
                    width: 105,
                    valueFormatter: (params) => params.data ? PricingRequestStatusDetails[params.data.statusId]?.name : "--"
                },
                {
                    field: 'modifiedDate',
                    headerName: 'Updated On',
                    width: 140,
                    valueFormatter: (params) => getFormattedDate(params.value, CONST_DATE_FORMAT)
                },
                {
                    field: 'createdBy',
                    headerName: 'Owner',
                    width: 140,
                },
                {
                    field: 'customerName',
                    width: 125,
                    headerName: 'Customer'
                },
                {
                    field: 'teamName',
                    headerName: 'Team',
                    width: 140,
                },
                {
                    field: 'approverName',
                    headerName: 'Approver',
                    width: 125
                }
            ],
        rowData: null,
        domLayout: 'autoHeight',
        onGridReady: (params) => {
            params.api.sizeColumnsToFit()
            fetchRequestList();
        },
        onFirstDataRendered: () => {
            gridRef.current?.api.sizeColumnsToFit();
        }
    }

    useWindowResize(() => gridRef.current?.api?.sizeColumnsToFit());
    
    return (
        <Card title={<h2 className='m-0'>Quotes</h2>}
            bodyStyle={{ minHeight: 'calc(100vh - 200px)' }}
            extra={
                <Link to='/quotes/new-quote'>
                    <Button type="primary"
                        icon={<PlusCircleOutlined rev={0} />}>
                        Add New
                    </Button>
                </Link>
            }
        >
            <div className="ag-theme-alpine ag-theme-alpine-custom h-90 w-100">
                <AgGridReact
                    ref={gridRef}
                    gridOptions={gridOptions} />
            </div>
        </Card>
    )
}

export { PricingRequests };

