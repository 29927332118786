import { Button, Card } from "antd";
import { Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { IsCollaborator, IsCompanyAdmin, IsPricingAdmin, IsPricingMember, IsProjectOwner } from "../../common/CommonFunctions";
import { useAuth } from "../auth";
import { AutomationEfficiencyStats } from "./AutomationEfficiencyStats";
import { AutomationTrendStats } from "./AutomationTrendStats";
import { MyTaskStats } from "./MyTaskStats";
import { ProjectCompletedStats } from "./ProjectCompletedStats";
import { ProjectStatusOvertimeStats } from "./ProjectStatusOvertimeStats";
import { ProjectStatusStats } from "./ProjectStatusStats";
import { RecentProjects } from "./RecentProjects";

const Dashboard: React.FC = () => {
    const { user_metadata } = useAuth();
    const hasProjectAccess = IsCompanyAdmin(user_metadata?.roles) || IsProjectOwner(user_metadata?.roles) || IsCollaborator(user_metadata?.roles);

    return (
        <>
            <Row>
                {
                    (IsCompanyAdmin(user_metadata?.roles) || IsProjectOwner(user_metadata?.roles)) &&
                    <Col sm={12} md={6} lg={4} className="mb-5">
                        <Card size="small" bordered={false}
                            className="border-start border-4 border-info h-100">
                            <Link to={'/projects/add-project'}>
                                <Card.Meta
                                    title="Create a new Project"
                                    description={
                                        <>
                                            <p>Rapidly generate responses for your RFP questions.</p>
                                            <Button type="primary" ghost>Create</Button>
                                        </>
                                    }
                                />
                            </Link>
                        </Card>
                    </Col>
                }
                {
                    hasProjectAccess &&
                    <Col sm={12} md={6} lg={4} className="mb-5">
                        <Card size="small" bordered={false}
                            className="border-start border-4 border-primary h-100">
                            <Link to={'/projects/list'}>
                                <Card.Meta
                                    title="My Projects"
                                    description={
                                        <>
                                            <p>Access your active projects.</p>
                                            <Button type="primary" ghost>View</Button>
                                        </>
                                    }
                                />
                            </Link>
                        </Card>
                    </Col>
                }
                {
                    (IsPricingAdmin(user_metadata?.roles) || IsPricingMember(user_metadata?.roles)) &&
                    <Col sm={12} md={6} lg={4} className="mb-5">
                        <Card size="small" bordered={false}
                            className="border-start border-4 border-info h-100">
                            <Link to='/quotes/new-quote'>
                                <Card.Meta
                                    title="Create a new Quote"
                                    description={
                                        <Button type="primary" ghost>Create</Button>
                                    }
                                />
                            </Link>
                        </Card>
                    </Col>
                }
            </Row>

            {
                hasProjectAccess &&
                <>
                    <Row>
                        <Col sm={12} md={6} className="mb-5">
                            <MyTaskStats />
                        </Col>

                        <Col sm={12} md={6} className="mb-5">
                            <RecentProjects />
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={12} md={6} className="mb-5">
                            <ProjectStatusStats />
                        </Col>
                        <Col sm={12} md={6} className="mb-5">
                            <AutomationEfficiencyStats />
                        </Col>
                        <Col sm={12} md={6} className="mb-5">
                            <ProjectStatusOvertimeStats />
                        </Col>
                        <Col sm={12} md={6} className="mb-5">
                            <AutomationTrendStats />
                        </Col>
                        <Col sm={12} md={6} className="mb-5">
                            <ProjectCompletedStats />
                        </Col>
                    </Row>
                </>
            }
        </>
    )
}

export { Dashboard };

