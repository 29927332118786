import { BorderOutlined, CheckOutlined, CopyOutlined } from '@ant-design/icons';
import { App, Button, Card, Drawer, Form, Input, Select, Space } from "antd";
import _ from "lodash";
import Markdown from 'markdown-to-jsx';
import { useCallback, useMemo, useRef, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { selectMasterDataByType } from "../../../../redux/MasterDataSlice";
import { useAppSelector } from "../../../../redux/hooks";
import { useGetDatasetSelectListQuery } from "../../../../redux/rtkquery/DatasetApi";
import { usePromptStreamMutation } from "../../../../redux/rtkquery/QuestionApi";
import { filterOptions, getDatasetGroups } from "../../../common/CommonFunctions";
import { ResponseFormat, StaticDataSets } from "../../../common/Enums";
import useCopyToClipboard from '../../../common/components/useCopyToClipboard';
import { GenerateResponseParams } from "../../../models";

const NinjaGenerator: React.FC = () => {
    const [form] = Form.useForm<GenerateResponseParams>();
    const [response, setResponse] = useState("");
    const [searchText, setSearchText] = useState("");
    const lastRequestRef = useRef<any>(null);
    const [ninjaModalOpen, setNinjaModalOpen] = useState(false);
    const { notification } = App.useApp();
    const { isCopied, copyToClipboard, resetCopied } = useCopyToClipboard();
    const textRef = useRef<HTMLDivElement>(null);

    const allDatasetListState = useGetDatasetSelectListQuery();
    const [triggerPromptStream, generateResponseResult] = usePromptStreamMutation();
    const responseFormats = _.filter(useAppSelector(state => selectMasterDataByType(state, "ResponseFormat")), x => x.value !== ResponseFormat.Custom);

    const onFinish = async (values: GenerateResponseParams) => {
        try {
            setResponse('');

            lastRequestRef.current = triggerPromptStream({
                data: values,
                onChunk: (chunk) => (setResponse((answer) => answer + chunk))
            })

        } catch (err) {
            console.error(err, "err");
        }
    }

    const onCancelResponse = () => {
        lastRequestRef.current?.abort();
    }

    const onGenerate = () => {

        if (!_.trim(searchText)) {
            notification.info({
                message: "Validation!",
                description: "Enter your query to generate response"
            })
            return;
        }

        const params = {
            question: _.trim(searchText),
            datasetId: StaticDataSets.Default,
            responseFormatId: ResponseFormat.Short
        };

        form.setFieldsValue(params);
        onFinish(params);
        setNinjaModalOpen(true);
        setSearchText('');
    }

    const onSearchTextChange = (e: any) => {
        setSearchText(e.target.value);
    }

    const onReset = () => {
        const params = {
            question: '',
            datasetId: StaticDataSets.Default,
            responseFormatId: ResponseFormat.Short
        };

        form.setFieldsValue(params);
        setResponse("");
        resetCopied();
    }

    const onCopy = () => {
        copyToClipboard({ copiedText: textRef.current?.innerText || "", copiedHTML: textRef.current?.innerHTML || "" });
    }

    const onModalClose = () => {
        onCancelResponse();
        setNinjaModalOpen(false);
    }

    const datasetGroups = useMemo(() => {
        return getDatasetGroups(allDatasetListState.data || [])
    }, [allDatasetListState]);

    const filterOption = useCallback(filterOptions, [allDatasetListState]);

    const handleKeyDown = (event: any) => {
        if ((event.ctrlKey || event.metaKey) && event.key === 'Enter') {
            form.submit();
        }
    }

    return (
        <>
            <Space.Compact className='w-100'>
                <Input size='large'
                    value={searchText}
                    onChange={onSearchTextChange}
                    onPressEnter={onGenerate}
                    placeholder="Have a quick query? Need some ninja-like guidance?"
                />
                <Button size='large'
                    type="default"
                    onClick={onGenerate}
                    loading={generateResponseResult.isLoading}
                    disabled={generateResponseResult.isLoading}
                >
                    Ask Away!
                </Button>
            </Space.Compact>

            <Drawer width={600} bodyStyle={{ padding: '12px 24px' }}
                forceRender={true}
                open={ninjaModalOpen}
                title="Ninja Generator"
                onClose={onModalClose}>

                <Form
                    layout="vertical"
                    form={form}
                    name="NinjaGenerator"
                    onFinish={onFinish}
                    autoComplete="off"
                >
                    <Form.Item name="question" label="Enter Question"
                        rules={[{ required: true, message: "Questoin is required", whitespace: true }]}>
                        <Input.TextArea autoSize
                            placeholder={`For example: "Write an email introducing my company to a prospective client", or
"Write a short description of my company's products and services."
Press Ctrl/Command + Enter to submit the request.`}
                            style={{ minHeight: '55px' }}
                            onKeyDown={handleKeyDown}
                        />
                    </Form.Item>

                    <Row>
                        <Col sm={12} md={6}>
                            <Form.Item name="datasetId" label="Dataset">
                                <Select
                                    showSearch
                                    options={datasetGroups}
                                    loading={allDatasetListState.isFetching}
                                    filterOption={filterOption}
                                />
                            </Form.Item>
                        </Col>
                        <Col>
                            <Form.Item name="responseFormatId" label="Response Format">
                                <Select
                                    options={responseFormats}
                                    fieldNames={{ label: "name" }}
                                />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Form.Item className="text-end mb-0">
                        <div className="d-flex justify-content-between">
                            <div>
                                <Button type="default"
                                    className='btn-stop-response'
                                    size="large"
                                    icon={<BorderOutlined rev={0} />}
                                    onClick={onCancelResponse}
                                    hidden={!generateResponseResult.isLoading}>
                                    Stop Ninja
                                </Button>
                            </div>

                            <Space>
                                <Button size="large"
                                    disabled={generateResponseResult.isLoading}
                                    onClick={onReset}>
                                    Clear
                                </Button>
                                <Button type="primary" size="large" htmlType="submit"
                                    loading={generateResponseResult.isLoading}>
                                    {
                                        generateResponseResult.isLoading ? "Generating" : "Generate"
                                    }
                                </Button>
                            </Space>
                        </div>
                    </Form.Item>
                </Form>

                <Card className="mt-5"
                    loading={generateResponseResult.isLoading}
                    hidden={!generateResponseResult.isLoading || response !== ""}
                />

                {
                    response !== "" &&
                    <>
                        <div className="border-start border-4 p-3 bg-gray-100 html-box mt-5">
                            <div className='text-end mb-2'>
                                <Button size='small'
                                    disabled={isCopied}
                                    icon={isCopied ? <CheckOutlined rev={0} /> : <CopyOutlined rev={0} />}
                                    onClick={onCopy}>
                                    {isCopied ? "Copied" : "Copy to Clipboard"}
                                </Button>
                            </div>
                            <div id="ninja_generator_response" ref={textRef}>
                                <Markdown>{response}</Markdown>
                            </div>
                            <div className='response-cursor' hidden={!generateResponseResult.isLoading}></div>
                        </div>
                    </>
                }

            </Drawer>
        </>
    )
}

export { NinjaGenerator };
